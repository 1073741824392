import React from "react"
import PropTypes from "prop-types"
import { Link, graphql } from "gatsby"
import _ from "lodash"

import Container from "../components/container"
import IconHeading from "../components/heading/icon-heading"
import ArticlePreview from "../components/list/article-preview"
import RegularButton from "../components/form/regular-button"

import TagsIcon from "../components/images/heading-tags.svg"
import Metadata from "../components/metadata"

const Tag = ({ pageContext, data }) => {
  const { tag } = pageContext
  const tagName = _.startCase(tag)
  const { edges, totalCount } = data.allMarkdownRemark

  return (
    <Container>
      <Metadata title={tagName}/>

      <h1>{tagName}</h1>
      <p>Browse all <strong>{totalCount}</strong> articles tagged with {tagName}.</p>

      <IconHeading text="Tagged articles" image={TagsIcon} style={{ marginTop: 70 + "px", marginBottom: -15 + "px" }}/>

      {edges.map(({ node }) => {
        const { slug } = node.fields

        return (
          <div key={slug}>
            <ArticlePreview post={node}/>
          </div>
        )
      })}

      <Link to="/tags">
        <RegularButton text="View all tags" style={{ marginTop: `25px` }}/>
      </Link>
    </Container>
  )
}

Tag.propTypes = {
  pageContext: PropTypes.shape({
    tag: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              title: PropTypes.string.isRequired,
            }),
            fields: PropTypes.shape({
              slug: PropTypes.string.isRequired,
            }),
          }),
        }).isRequired,
      ),
    }),
  }),
}

export default Tag

export const pageQuery = graphql`
  query($tag: String) {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "MMMM D, YYYY")
            excerpt
          }
          excerpt
        }
      }
    }
  }
`
